<template>
  <div>
    <div class="navigation-dsk">
      <b-navbar class="dashboard-navbar fixed-top" toggleable="lg">
        <b-navbar-brand>
          <router-link :to="{ name: 'userRoute-dashboard' }">
            <img
              src="/img/digital-staging-logo.svg"
              alt="digital-staging-logo"
              style="height: 40px"
            />
          </router-link>
        </b-navbar-brand>
        <b-navbar-nav class="ml-auto d-flex align-items-center">
          <b-nav-item class="notifications" href="https://digitalplan.digitalstaging.co.jp/" 
          target="_blank" 
          style="text-align:center;border: 1px solid #1f7ce5;border-radius: 20px;box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);">
            <div class="blue-text" style="font-size:10px;width:200px;height:35px;"><img
              style="width:150px;"
              src="../../../public/img/home/plan.png"
              alt="Notification Bell"
            /></div>
            <div class="blue-text" style="font-size:10px;">図面から360°バーチャルツアーを</div>
          </b-nav-item>
          <b-nav-item class="notifications" href="https://ai.digitalstaging.co.jp/" 
          target="_blank" 
          style="text-align:center;border: 1px solid #1f7ce5;border-radius: 20px;box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);height:68px;display: flex;align-items: center;">
            <div class="blue-text" style="font-size:10px;width:200px;height:35px;"><img
              style="width:150px;"
              src="../../../public/img/home/digitalstaging_ai_logo.png"
              alt="Notification Bell"
            /></div>
            <div class="blue-text" style="font-size:10px;">床・壁・天井の変更5,500円で無制限利用可能</div>
          </b-nav-item>
          <b-nav-item class="notifications" v-b-toggle.notification-sidebar>
            <img
              src="../../../public/img/icons/ico-notification-bell.svg"
              alt="Notification Bell"
            />
            <div class="text">
              <span>{{ notifications.unread }}</span>
            </div>
          </b-nav-item>
          <b-nav-item-dropdown class="pr-2" right>
            <template slot="button-content">
              <p class="m-0 mt-1 blue-text font-weight-bold">
                <img
                  src="../../../public/img/icons/ico-profile-image.svg"
                  alt="Profile Image"
                />
                <span class="mr-3">
                  {{
                    currentUser.user_profile
                      ? currentUser.user_profile.first_name &&
                        currentUser.user_profile.last_name
                        ? currentUser.user_profile.last_name +
                          ' ' +
                          currentUser.user_profile.first_name +
                          '様'
                        : currentUser.email
                      : currentUser.email
                  }}
                </span>
              </p>
            </template>
            <b-dropdown-item>
              <span class="m-0 mt-1 blue-text font-weight-bold text-size" type="btn" v-on:click="$bvModal.show('logout-modal')">サインアウト</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-navbar>
      <div class="side-navigation">
        <div class="side-nav-menu">
          <router-link
            :class="
              currentRoute.includes('userRoute-dashboard')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'userRoute-dashboard' }"
            v-waves.light
          >
            <p class="menu-element">
              <img
                src="../../../public/img/icons/ico-home.svg"
                class="ico-side-nav-menu"
                alt="Home"
              />
              <span class="ml-4">ホーム</span>
            </p>
          </router-link>
          <a
            :class="
              currentRoute.includes('userRoute-profile-user-info') ||
              currentRoute.includes('userRoute-profile-email-update') ||
              currentRoute.includes('userRoute-profile-password-reset')
                ? 'side-nav-item side-nav-item-active '
                : 'side-nav-item'
            "
            href="#"
            not-collapsed
            v-b-toggle.profile-dsk-collapse
            v-waves.light
          >
            <p class="menu-element">
              <img
                src="../../../public/img/icons/ico-profile-default.svg"
                class="ico-side-nav-menu"
                alt="Profile"
              />
              <span class="w-100 ml-4">プロフィール</span>
              <font-awesome-icon
                class="float-right"
                :icon="{ prefix: 'fas', iconName: 'chevron-down' }"
              />
            </p>
          </a>
          <b-collapse
            id="profile-dsk-collapse"
            class="side-nav-collapse"
            :visible="
              currentRoute.includes('userRoute-profile-user-info') ||
              currentRoute.includes('userRoute-profile-email-update') ||
              currentRoute.includes('userRoute-profile-password-reset')
                ? true
                : false
            "
          >
            <router-link
              :class="
                currentRoute.includes('userRoute-profile-user-info')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'userRoute-profile-user-info' }"
              v-waves.light
            >
              <p class="menu-element pl-3">プロフィール編集</p>
            </router-link>
            <router-link
              :class="
                currentRoute.includes('userRoute-profile-email-update')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'userRoute-profile-email-update' }"
              v-waves.light
            >
              <p class="menu-element pl-3">メールアドレス変更</p>
            </router-link>
            <router-link
              :class="
                currentRoute.includes('userRoute-profile-password-reset')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'userRoute-profile-password-reset' }"
              v-waves.light
            >
              <p class="menu-element pl-3">パスワード変更</p>
            </router-link>
          </b-collapse>
          <a
            class="side-nav-item not-collapsed"
            :class="
              currentRoute.includes('userRoute-staging-new-request') ||
              currentRoute.includes('userRoute-staging-pending') ||
              currentRoute.includes('userRoute-staging-delivered') ||
              currentRoute.includes('userRoute-staging-confirmed')
                ? 'side-nav-item side-nav-item-active '
                : 'side-nav-item'
            "
            href="#"
            not-collapsed
            v-b-toggle.staging-dsk-collapse
            v-waves.light
          >
            <p class="menu-element">
              <img
                src="../../../public/img/icons/ico-staging-default.svg"
                class="ico-side-nav-menu"
                alt="Staging"
              />
              <span class="w-100 ml-4">ステージング</span>
              <font-awesome-icon
                class="float-right"
                :icon="{ prefix: 'fas', iconName: 'chevron-down' }"
              />
            </p>
          </a>
          <b-collapse
            id="staging-dsk-collapse"
            class="side-nav-collapse"
            :visible="
              currentRoute.includes('userRoute-staging-select-survice') ||
              currentRoute.includes('userRoute-staging-new-request') ||
              currentRoute.includes('userRoute-staging-pending') ||
              currentRoute.includes('userRoute-staging-delivered') ||
              currentRoute.includes('userRoute-staging-confirmed')
                ? true
                : false
            "
          >
            <router-link
              :class="
                currentRoute.includes('userRoute-staging-select-survice')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'userRoute-staging-select-survice' }"
              v-waves.light
            >
              <p class="menu-element pl-3">新規依頼</p>
            </router-link>
            <router-link
              :class="
                currentRoute.includes('userRoute-staging-pending')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'userRoute-staging-pending' }"
              v-waves.light
            >
              <p class="menu-element pl-3">依頼中</p>
            </router-link>
            <router-link
              :class="
                currentRoute.includes('userRoute-staging-delivered')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'userRoute-staging-delivered' }"
              v-waves.light
            >
              <p class="menu-element pl-3">納品確認</p>
            </router-link>
            <router-link
              :class="
                currentRoute.includes('userRoute-staging-confirmed')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'userRoute-staging-confirmed' }"
              v-waves.light
            >
              <p class="menu-element pl-3">納品確認済</p>
            </router-link>
            <router-link
              :class="
                currentRoute.includes('userRoute-staging-design-list')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'userRoute-staging-design-list' }"
              v-waves.light
            >
              <p class="menu-element pl-3">デザインリスト</p>
            </router-link>
          </b-collapse>
          <router-link
            :class="
              currentRoute.includes('userRoute-billing-history')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'userRoute-billing-history' }"
            v-waves.light
          >
            <p class="menu-element">
              <img
                src="../../../public/img/icons/ico-billing-history-default.svg"
                class="ico-side-nav-menu"
                alt="Billing History"
              />
              <span class="ml-4">課金履歴</span>
            </p>
          </router-link>

          <router-link
            :class="
              currentRoute.includes('anonymousRoute-design-list')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'anonymousRoute-design-list' }"
            v-waves.light
          >
            <p class="menu-element">
              <img
                src="../../../public/img/icons/clipboard-list-solid.svg"
                class="ico-side-nav-menu"
                alt="Design List"
              />
              <span class="ml-4">デザインリスト</span>
            </p>
          </router-link>

          <router-link
            :class="
              currentRoute.includes('userRoute-shooting-request')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'userRoute-shooting-request' }"
            v-waves.light
          >
            <p class="menu-element">
              <img
                src="../../../public/img/icons/icon-photographing.png"
                class="ico-side-nav-menu"
                alt="Shooting Request"
              />
              <span class="ml-4">撮影代行</span>
            </p>
          </router-link>

          <a
            class="side-nav-item"
            href="#"
            v-waves.light
            v-on:click="$bvModal.show('logout-modal')"
          >
            <p class="menu-element">
              <img
                src="../../../public/img/icons/ico-logout-default.svg"
                class="ico-side-nav-menu"
                alt="Logout"
              />
              <span class="ml-4">サインアウト</span>
            </p>
          </a>
        </div>
      </div>
    </div>

    <div class="navigation-mbl">
      <b-navbar class="dashboard-navbar fixed-top" toggleable="lg">
        <b-navbar-brand>
          <font-awesome-icon
            class="mr-3 blue-text"
            :icon="{ prefix: 'fas', iconName: 'bars' }"
            v-b-toggle.dashboard-sidebar-ml
          />
          <router-link :to="{ name: 'userRoute-dashboard' }">
            <img
              src="/img/digital-staging-logo.svg"
              alt="digital-staging-logo"
              style="height: 40px"
            />
          </router-link>
        </b-navbar-brand>
        <b-navbar-nav class="ml-auto">
          <div class="pt-2 d-flex">
            <b-nav-item class="notifications" v-b-toggle.notification-sidebar>
              <font-awesome-icon
                class="icon"
                :icon="{ prefix: 'fas', iconName: 'bell' }"
              />
              <span class="badge">{{ notifications.unread }}</span>
            </b-nav-item>
            <b-nav-item-dropdown toggle-class="pr-2" right>
              <template slot="button-content">
                <p class="m-0 mt-1 blue-text font-weight-bold user-icon">
                  <font-awesome-icon
                    class="mr-2"
                    :icon="{ prefix: 'fas', iconName: 'user-circle' }"
                  />
                  <font-awesome-icon
                    :icon="{ prefix: 'fas', iconName: 'caret-down' }"
                  />
                </p>
              </template>
              <a class="dropdown-item" href="#">
                <p class="m-0 font-weight-bold">
                  <font-awesome-icon
                    class="mr-2"
                    :icon="{ prefix: 'fas', iconName: 'user-circle' }"
                  />
                  <span class="mr-3 dropdown-font-size">
                    {{
                      currentUser.user_profile
                        ? currentUser.user_profile.first_name &&
                          currentUser.user_profile.last_name
                          ? currentUser.user_profile.last_name +
                            ' ' +
                            currentUser.user_profile.first_name +
                            '様'
                          : currentUser.email
                        : currentUser.email
                    }}
                  </span>
                </p>
              </a>
              <b-dropdown-item>
                <a class="dropdown-item" href="#">
                  <span class="m-0 font-weight-bold blue-text text-size" type="btn" v-on:click="$bvModal.show('logout-modal')">サインアウト</span>
                </a>
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </div>
          <b-sidebar id="dashboard-sidebar-ml" backdrop>
            <div class="side-nav-menu">
              <router-link
                :class="
                  currentRoute.includes('userRoute-dashboard')
                    ? 'side-nav-item side-nav-item-active'
                    : 'side-nav-item'
                "
                :to="{ name: 'userRoute-dashboard' }"
                v-waves.light
              >
                <p class="menu-element">
                  <img
                    src="../../../public/img/icons/ico-home.svg"
                    class="ico-side-nav-menu"
                    alt="Home"
                  />
                  <span class="ml-4">ホーム</span>
                </p>
              </router-link>
              <a
                :class="
                  currentRoute.includes('userRoute-profile-user-info') ||
                  currentRoute.includes('userRoute-profile-email-update') ||
                  currentRoute.includes('userRoute-profile-password-reset')
                    ? 'side-nav-item side-nav-item-active '
                    : 'side-nav-item'
                "
                href="#"
                not-collapsed
                v-b-toggle.profile-mbl-collapse
                v-waves.light
              >
                <p class="menu-element">
                  <img
                    src="../../../public/img/icons/ico-profile-default.svg"
                    class="ico-side-nav-menu"
                    alt="Profile"
                  />
                  <span class="w-100 ml-4">プロフィール</span>
                  <font-awesome-icon
                    class="float-right"
                    :icon="{ prefix: 'fas', iconName: 'chevron-down' }"
                  />
                </p>
              </a>
              <b-collapse
                id="profile-mbl-collapse"
                class="side-nav-collapse"
                :visible="
                  currentRoute.includes('userRoute-profile-user-info') ||
                  currentRoute.includes('userRoute-profile-email-update') ||
                  currentRoute.includes('userRoute-profile-password-reset')
                    ? true
                    : false
                "
              >
                <router-link
                  :class="
                    currentRoute.includes('userRoute-profile-user-info')
                      ? 'side-nav-collapse-item side-nav-collapse-item-active'
                      : 'side-nav-collapse-item'
                  "
                  :to="{ name: 'userRoute-profile-user-info' }"
                  v-waves.light
                >
                  <p class="menu-element pl-3">プロフィール編集</p>
                </router-link>
                <router-link
                  :class="
                    currentRoute.includes('userRoute-profile-email-update')
                      ? 'side-nav-collapse-item side-nav-collapse-item-active'
                      : 'side-nav-collapse-item'
                  "
                  :to="{ name: 'userRoute-profile-email-update' }"
                  v-waves.light
                >
                  <p class="menu-element pl-3">メールアドレス変更</p>
                </router-link>
                <router-link
                  :class="
                    currentRoute.includes('userRoute-profile-password-reset')
                      ? 'side-nav-collapse-item side-nav-collapse-item-active'
                      : 'side-nav-collapse-item'
                  "
                  :to="{ name: 'userRoute-profile-password-reset' }"
                  v-waves.light
                >
                  <p class="menu-element pl-3">パスワード変更</p>
                </router-link>
              </b-collapse>
              <a
                :class="
                  currentRoute.includes('userRoute-staging-new-request') ||
                  currentRoute.includes('userRoute-staging-pending') ||
                  currentRoute.includes('userRoute-staging-delivered') ||
                  currentRoute.includes('userRoute-staging-confirmed')
                    ? 'side-nav-item side-nav-item-active '
                    : 'side-nav-item'
                "
                href="#"
                not-collapsed
                v-b-toggle.staging-mbl-collapse
                v-waves.light
              >
                <p class="menu-element">
                  <img
                    src="../../../public/img/icons/ico-staging-default.svg"
                    class="ico-side-nav-menu"
                    alt="Staging"
                  />
                  <span class="w-100 ml-4">ステージング</span>
                  <font-awesome-icon
                    class="float-right"
                    :icon="{ prefix: 'fas', iconName: 'chevron-down' }"
                  />
                </p>
              </a>
              <b-collapse
                id="staging-mbl-collapse"
                class="side-nav-collapse"
                :visible="
                  currentRoute.includes('userRoute-staging-select-survice') ||
                  currentRoute.includes('userRoute-staging-new-request') ||
                  currentRoute.includes('userRoute-staging-pending') ||
                  currentRoute.includes('userRoute-staging-delivered') ||
                  currentRoute.includes('userRoute-staging-confirmed')
                    ? true
                    : false
                "
              >
                <router-link
                  :class="
                    currentRoute.includes('userRoute-staging-new-request') ||
                    currentRoute.includes('userRoute-staging-select-survice')
                      ? 'side-nav-collapse-item side-nav-collapse-item-active'
                      : 'side-nav-collapse-item'
                  "
                  :to="{ name: 'userRoute-staging-select-survice' }"
                  v-waves.light
                >
                  <p class="menu-element pl-3">新規依頼</p>
                </router-link>
                <router-link
                  :class="
                    currentRoute.includes('userRoute-staging-pending')
                      ? 'side-nav-collapse-item side-nav-collapse-item-active'
                      : 'side-nav-collapse-item'
                  "
                  :to="{ name: 'userRoute-staging-pending' }"
                  v-waves.light
                >
                  <p class="menu-element pl-3">依頼中</p>
                </router-link>
                <router-link
                  :class="
                    currentRoute.includes('userRoute-staging-delivered')
                      ? 'side-nav-collapse-item side-nav-collapse-item-active'
                      : 'side-nav-collapse-item'
                  "
                  :to="{ name: 'userRoute-staging-delivered' }"
                  v-waves.light
                >
                  <p class="menu-element pl-3">納品確認</p>
                </router-link>
                <router-link
                  :class="
                    currentRoute.includes('userRoute-staging-confirmed')
                      ? 'side-nav-collapse-item side-nav-collapse-item-active'
                      : 'side-nav-collapse-item'
                  "
                  :to="{ name: 'userRoute-staging-confirmed' }"
                  v-waves.light
                >
                  <p class="menu-element pl-3">納品確認済</p>
                </router-link>
                <router-link
                  :class="
                    currentRoute.includes('userRoute-staging-design-list')
                      ? 'side-nav-collapse-item side-nav-collapse-item-active'
                      : 'side-nav-collapse-item'
                  "
                  :to="{ name: 'userRoute-staging-design-list' }"
                  v-waves.light
                >
                  <p class="menu-element pl-3">デザインリスト</p>
                </router-link>
              </b-collapse>
              <router-link
                :class="
                  currentRoute.includes('userRoute-billing-history')
                    ? 'side-nav-item side-nav-item-active'
                    : 'side-nav-item'
                "
                :to="{ name: 'userRoute-billing-history' }"
                v-waves.light
              >
                <p class="menu-element">
                  <img
                    src="../../../public/img/icons/ico-billing-history-default.svg"
                    class="ico-side-nav-menu"
                    alt="Billing History"
                  />
                  <span class="ml-4">課金履歴</span>
                </p>
              </router-link>

              <router-link
                :class="
                  currentRoute.includes('anonymousRoute-design-list')
                    ? 'side-nav-item side-nav-item-active'
                    : 'side-nav-item'
                "
                :to="{ name: 'anonymousRoute-design-list' }"
                v-waves.light
              >
                <p class="menu-element">
                  <img
                    src="../../../public/img/icons/clipboard-list-solid.svg"
                    class="ico-side-nav-menu"
                    alt="Design List"
                  />
                  <span class="ml-4">デザインリスト</span>
                </p>
              </router-link>

              <router-link
                :class="
                  currentRoute.includes('userRoute-shooting-request')
                    ? 'side-nav-item side-nav-item-active'
                    : 'side-nav-item'
                "
                :to="{ name: 'userRoute-shooting-request' }"
                v-waves.light
              >
                <p class="menu-element">
                  <img
                    src="../../../public/img/icons/icon-photographing.png"
                    class="ico-side-nav-menu"
                    alt="Shooting Request"
                  />
                  <span class="ml-4">撮影代行</span>
                </p>
              </router-link>

              <a
                class="side-nav-item"
                href="#"
                v-waves.light
                v-on:click="$bvModal.show('logout-modal')"
              >
                <p class="menu-element">
                  <img
                    src="../../../public/img/icons/ico-logout-default.svg"
                    class="ico-side-nav-menu"
                    alt="Logout"
                  />
                  <span class="ml-4">サインアウト</span>
                </p>
              </a>
            </div>
          </b-sidebar>
        </b-navbar-nav>
      </b-navbar>
    </div>

    <b-sidebar
      id="notification-sidebar"
      width="400px"
      right
      no-header
      shadow
      backdrop
      v-model="notifications.visible"
    >
      <template #default="{ hide }">
        <div class="header">
          <h5>メッセージ ({{ notifications.unread }})</h5>
          <h5 class="cursor-pointer" @click="hide">
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
          </h5>
        </div>
        <div class="body">
          <div v-if="notifications.data.length > 0">
            <div
              :class="el.unread === 1 ? 'item' : 'item item-read'"
              v-for="(el, i) in notifications.data"
              :key="i"
            >
              <p class="m-0">
                <span class="font-weight-bold">
                  依頼ID SR-{{ JSON.parse(el.data).sr_id }}:
                </span>
                {{ el.title }}
              </p>
              <p class="mt-1 mb-0" style="font-size: 14px">
                {{ formatDate(el.updated_at) }}
              </p>
            </div>
            <p
              class="text-center white-text"
              v-if="notifications.cur_page < notifications.page_count"
              @click="showMoreNotifs"
              style="width: 100%"
            >
              read more>>
            </p>
          </div>
          <div v-else>
            <h5 class="mt-4 text-center white-text">メッセージがありません</h5>
          </div>
        </div>
      </template>
    </b-sidebar>

    <logout-modal></logout-modal>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Services
import {
  cus_notificationService_GetNotifications,
  cus_notificationService_UpdateNotificationsToRead,
} from '../../services/customer/notifications';

// Import Moment JS
import moment from 'moment';

// Import Custom Components
import logoutModal from '../Logout-Modal.vue';

export default {
  components: {
    'logout-modal': logoutModal,
  },

  data() {
    return {
      notifications: {
        data: [],
        unread: 0,
        visible: false,
        page_count: 1,
        cur_page: 1,
      },
    };
  },

  computed: {
    ...mapGetters(['currentUser', 'currentRoute']),
  },

  watch: {
    $route() {
      this.setNotifications();
    },

    'notifications.visible'(newValue) {
      let ids = this.notifications.data
        .map((el) => {
          if (el.unread === 1) {
            return el.id;
          }
        })
        .filter((e) => e != null);

      this.updateNotificationCount(newValue, ids);
    },
  },

  mounted() {
    this.setNotifications();
  },

  methods: {
    setNotifications(page) {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      cus_notificationService_GetNotifications({
        token: accessToken,
        page: page ? page : 1,
      })
        .then((response) => {
          let result_data = response.data.result;
          _this.notifications.data = _this.notifications.data.concat(
            result_data.data.data
          );

          _this.notifications.unread = result_data.unread_count;
          _this.notifications.page_count = result_data.data.last_page;
          _this.notifications.cur_page = result_data.data.current_page;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showMoreNotifs() {
      this.setNotifications(this.notifications.cur_page + 1);
    },

    async updateNotificationCount(sideBarState, ids) {
      let _this = this;

      _this.notifications.data = _this.notifications.data.sort(function (a, b) {
        return b.unread - a.unread;
      });

      let unread_notifs = _this.notifications.data.filter((n) => n.unread == 1);
      var unread_ids = unread_notifs.map(function (val) {
        return val.id;
      });

      if (!sideBarState) {
        if (unread_ids.length > 0) {
          let accessToken = localStorage.getItem('_accessToken');

          await cus_notificationService_UpdateNotificationsToRead({
            ids: unread_ids,
            token: accessToken,
          })
            .then((response) => {
              let result_data = response.data.result;
              _this.notifications.unread = result_data.unread_count;

              //update read
              unread_ids.forEach((id) => {
                let objIndex = _this.notifications.data.findIndex(
                  (obj) => obj.id == id
                );
                _this.notifications.data[objIndex].unread = 0;
              });
            })
            .catch((error) => {
              console.log(error);
            });
          _this.notifications.data = [];
          this.setNotifications();
        }
      }
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:MM');
    },
  },
};
</script>

<style scoped>
/* CSS Init */
.side-nav-collapse .side-nav-collapse-item {
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #000;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.side-nav-collapse .side-nav-collapse-item:hover {
  background: #757575;
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.side-nav-collapse .side-nav-collapse-item-active {
  background: #fff;
  color: #034ea1;
  font-weight: bold;
}

#notification-sidebar .header {
  display: flex;
  justify-content: space-between;
  background: #034ea1;
  color: #fff;
  padding: 28px 10px 10px 10px;
  border-bottom: 1px solid #fff;
}

#notification-sidebar .body {
  background: #034ea1;
  height: 100%;
  padding: 10px 10px 40px 10px;
  overflow: auto;
}

#notification-sidebar .body .item {
  color: #fff;
  border: 1px solid #fff;
  padding: 8px;
  margin: 10px 10px 10px 10px;
  border-radius: 10px;
}

#notification-sidebar .body .item-read {
  opacity: 0.5;
}

/* Desktop Main Navbar and Sidebar */
.navigation-dsk {
  display: block;
  font-size: 12px;
}

@media only screen and (max-width: 991px) {
  .navigation-dsk {
    display: none;
  }
}

.navigation-dsk .dashboard-navbar {
  background: #fff;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.navigation-dsk .dashboard-navbar .notifications {
  position: relative;
  margin: -4px 18px 0 0;
}

.navigation-dsk .dashboard-navbar .notifications .icon {
  color: #167ae8;
  font-size: 17px;
}

.navigation-dsk .dashboard-navbar .notifications .badge {
  position: absolute;
  background: #ff0000;
  color: #fff;
  border-radius: 20px;
  top: 4px;
  right: -2px;
}

.navigation-dsk .side-navigation {
  background: #fff;
  position: fixed;
  top: 50px;
  height: 100%;
  width: 300px;
  overflow: auto;
  border-right: 2px solid rgba(0, 0, 0, 0.1);
}

@media print {
  .navigation-dsk .side-navigation {
    display: none;
    width: 0;
    height: 0;
  }
}

.navigation-dsk .side-nav-menu {
  margin-top: 50px;
  padding: 10px 20px 20px 20px;
  margin-bottom: 50px;
}

.navigation-dsk .side-nav-menu .side-nav-item {
  margin-bottom: 10px;
  background: #e9e9e9;
  border-radius: 5px;
  /* padding: 15px 10px 15px 10px; */
  padding: 0px 10px 0px 10px;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #000;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.navigation-dsk .side-nav-menu .side-nav-item:hover {
  background: #757575;
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.navigation-dsk .side-nav-menu .side-nav-item-active {
  background: #034ea1;
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

/* Mobile Main Navbar and Sidebar */
.navigation-mbl {
  display: none;
}

@media only screen and (max-width: 991px) {
  .navigation-mbl {
    display: block;
    font-size: 12px;
  }
}

.navigation-mbl .dashboard-navbar {
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.navigation-mbl .dashboard-navbar .notifications {
  position: relative;
  margin: -4px 26px 0 0;
}

.navigation-mbl .dashboard-navbar .notifications .icon {
  color: #167ae8;
  font-size: 17px;
}

.navigation-mbl .dashboard-navbar .notifications .badge {
  position: absolute;
  background: #ff0000;
  color: #fff;
  border-radius: 20px;
  top: 4px;
  right: -10px;
}

.navigation-mbl .navbar {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
@media print {
  .navbar {
    display: none;
    width: 0;
    height: 0;
  }
}

.navigation-mbl .side-nav-menu {
  padding: 20px;
}

.navigation-mbl .side-nav-menu .side-nav-item {
  margin-bottom: 10px;
  background: #e9e9e9;
  border-radius: 5px;
  padding: 0 10px 0 10px;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #000;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.navigation-mbl .side-nav-menu .side-nav-item:hover {
  background: #757575;
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.navigation-mbl .side-nav-menu .side-nav-item-active {
  background: #034ea1;
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.menu-element {
  display: flex;
  align-items: center;
  height: 52px;
  width: 100%;
  margin: 0;
}

.ico-side-nav-menu {
  height: 20px;
  width: 20px;
}

.ico-logout-default {
  width: 18px;
  height: 19px;
  margin: 13px 37px 13px 10px;
}

a.side-nav-item {
  display: none;
}

.side-nav-item-active .ico-side-nav-menu {
  filter: invert(1);
}

.text-size {
  font-size: 12px;
}
.dropdown-font-size {
  font-size: 12px;
}

.user-icon {
  font-size: 16px;
}

.text {
  display: inline-block;
  padding: 0.25rem 0.35rem;
  font-size: 9px;
  font-weight: 450;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: absolute;
  background: #ff0000;
  color: #fff;
  border-radius: 20px;
  top: 9px;
  right: 2px;
}
</style>
